import React from "react";

import Tag from "../tag";
const PricingLite = ({ buyButton }) => {
  return (
    <div className={`pricing-lite-block`}>
      <div>
        <header className="display-inline-block position-relative">
          <h1 className="bold">LITE PLAN</h1>
          {/* <Tag tagName="New" /> */}
        </header>
        <summary className="bold">Discover More , Commit Less</summary>
        <div
          className={`full-screen-border ${
            window.innerWidth >= 600 && window.innerWidth <= 991
              ? "display-none"
              : ""
          }`}
        />
        <main>
          <p className="bold">200 GB Bandwidth 50 GB Storage</p>
          <p>3 month validity. Starter Plan features.</p>
        </main>
      </div>

      <a href={buyButton.href} className="lite-plan-buy-button ">
        <div className="cell-center">
          <span>START NOW</span>
          <span className="amt">$49</span>
        </div>
      </a>
    </div>
  );
};

export default PricingLite;
